.Login {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}
.Login__container {
  background: #1d1d1d;
  color: #eff3fa;
  width: 724px;
  height: 300px;
  display: inline-grid;
  grid-template-columns: 50% 1px 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-content: center;
}
.Login__titlecontainer {
  width: 100%;
  height: 100%;
}
.Login__title {
  letter-spacing: 3.94px;
  text-align: right;
  font-size: 42px;
  height: 100%;
  width: 100%;
  display: inline-grid;
  justify-content: center;
  align-content: center;
}
.Login__company {
  font-size: 42px;
  font-weight: 300;
}
.Login__project {
  font-weight: 900;
}
.Login__fields {
  height: 100%;
  width: 100%;
  display: inline-grid;
  justify-content: center;
  align-content: center;
  font-weight: 300;
  border-width: 0 0 1px;
  margin-top: 20px;
}
.Login__vl {
  border-right: 1px solid rgba(228, 234, 246, 0.2);
  height: 180px;
  margin-top: auto;
  margin-bottom: auto;
}
.Login__username {
  font-size: 16px;
  letter-spacing: 3px;
  background: transparent;
  border-color: #979797;
  width: 281px;
  margin-bottom: 37px;
  margin-top: 30px;
  color: #fff;
}
.Login__password {
  font-size: 16px;
  letter-spacing: 3px;
  background: transparent;
  border-color: #979797;
  width: 281px;
  color: #fff;
}
input::placeholder {
  color: #fff;
}
input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-width: 0 0 1px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  outline: none;
}
.Login__login {
  /* margin-top: 30px; */
  margin-left: auto;
  cursor: pointer;
}
.Login__invalid {
  margin-right: auto;
  color: #f00;
}
.Login__footer {
  margin-top: 30px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  /* grid-template-columns: auto auto auto; */
}
