.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  font-weight: bold;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  height: 100%;
}

.MuiFormLabel-root.Mui-error {
  color: white !important;
}

.MuiFormHelperText-root.Mui-error {
  color: white !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline {
  color: white !important;
}

.MuiInput-underline.Mui-error::after {
  border-bottom-color: white !important;
}

.MuiPaper-root.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  overflow: unset;
  position: static;
}

.MuiTableCell-root.MuiTableCell-head {
  font-weight: bold;
  background-color: #1a1a1a;
  line-height: 1.1;
  position: sticky;
  top: 3.8em;
  /*font-family: Impact !important;*/
}

.MuiTypography-root.MuiTablePagination-caption {
  font-weight: bold;
}
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
  background-color: #1a1a1a;
}

.MuiPaper-root.MuiSnackbarContent-root {
  background-color: #1a1a1a;
  color: white;
}

.MuiSnackbarContent-message {
  color: white;
}

.MuiTableCell-alignRight {
  text-align: center !important;
}

thead {
  white-space: nowrap;
  text-align: center;
}

.MuiInputBase-root {
  font-size: 1em;
}

.MuiToolbar-root.MuiToolbar-regular .filter-field {
  padding-left: 15em;
}

.MuiToolbar-root .MuiTablePagination-spacer {
  flex: 0;
}

.MuiToolbar-root.MuiToolbar-regular
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  margin-left: 30%;
  margin-right: auto;
  width: 20%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
  position: sticky;
  /* position: fixed; */
  /* width: 95.5%; */
  bottom: 0;
  left: 2em;
}

.MuiDrawer-root.MuiDrawer-docked {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: #333;
}
:focus {
  outline: 0 !important;
}

.MenuBlockID {
  /* position: fixed; */
  position: sticky;
  left: 0;
  top: -10em;
  z-index: 10;
  background-color: #333;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  overflow-y: unset;
  overflow-x: unset;
  position: sticky;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters
  > div {
  position: sticky;
  left: 30em;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters
  > p {
  position: sticky;
  bottom: 1em;
  left: 21em;
}
[class*='RaList-bulkActionsDisplayed-']
  [class*='RaBulkActionsToolbar-toolbar-'],
[class*='RaBulkActionsToolbar-toolbar-'][class*='collapsed'] {
  background-color: #1a1a1a;
}

/* MuiToolbar-root MuiToolbar-regular RaBulkActionsToolbar-toolbar-73 */
/* 
div[class^='RaPaginationActions-actions'] {
  position: sticky;
  bottom: 0.6em;
  left: 25em;
}
*/
/*

div[class^='RaPaginationActions-actions'] {
  position: fixed;
  bottom: 0.6em;
  left: 25em;
} */
